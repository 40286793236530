import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, ButtonModule } from '@coreui/angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker'; // https://github.com/danielmoncada/date-time-picker
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { DatetimerangepickerInputComponent, SelectMenuInputComponent } from "../../components/forms";
import { IconModule } from '@coreui/icons-angular';
import { FormsModule } from '@angular/forms';
import { PublicService } from 'src/app/_services/public.service';
import { AuditService } from 'src/app/_services/audit.service';
import { listFilterConfigModel } from 'src/app/_models/content.model';

const dateTimeFormat = {
  parseInput: 'DD MMM YYYY, h:mm A',
  fullPickerInput: 'DD MMM YYYY, h:mm A',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'h:mm a',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-list-filters',
  standalone: true,
  imports: [IconModule, GridModule, FormModule, FormsModule, ButtonModule, OwlDateTimeModule, OwlNativeDateTimeModule, OwlMomentDateTimeModule, CommonModule, SelectMenuInputComponent, DatetimerangepickerInputComponent],
  templateUrl: './list-filters.component.html',
  styleUrl: './list-filters.component.scss',
  providers: [{
    provide: OWL_DATE_TIME_FORMATS,
    useValue: dateTimeFormat
  }]
})
export class ListFiltersComponent implements OnInit {
  @Input()
  config!: listFilterConfigModel;
  activePeriod: Date[] = [];

  @Output() onFilter = new EventEmitter<{ formControlName: string, value: string }>()

  minDate: Date | null = null;

  inputLanguageConfig = {
    id: 'language-input',
    name: 'language-input',
    label: 'Language',
    value: '1',
    disabled: false,
    error_message: '',
    options: [
      {
        value: '1',
        label: 'English',
        disabled: false,
        selected: false
      },
      {
        value: '4852764',
        label: 'Traditional Chinese',
        disabled: false,
        selected: false
      },
      {
        value: '5017431',
        label: 'Japanese',
        disabled: false,
        selected: false
      },
      {
        value: '5028697',
        label: 'Korean',
        disabled: false,
        selected: false
      },
      {
        value: '4561158',
        label: 'Indonesian',
        disabled: false,
        selected: false
      },
      {
        value: '5146990',
        label: 'Vietnamese',
        disabled: false,
        selected: false
      },
      {
        value: '4938438',
        label: 'Thai',
        disabled: false,
        selected: false
      }
    ]
  };

  inputActivePeriodConfig = {
    id: 'active-period-input',
    name: 'active-period-input',
    label: 'Active Period',
    placeholder: 'Select date range',
    value: [],
    disabled: false,
    error_message: 'Please select a date range',
    error: [''],
    calendar_only: true,
    picker_mode_single: false
  };

  inputMakerStatusConfig = {
    id: 'maker-status-input',
    name: 'maker-status-input',
    label: 'Status',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All status',
        disabled: false,
        selected: true
      },
      {
        value: 'APPROVED',
        label: 'Approved',
        disabled: false,
        selected: false
      },
      {
        value: 'SUBMITTED_FOR_APPROVAL',
        label: 'Pending approval',
        disabled: false,
        selected: false
      },
      {
        value: 'REJECTED',
        label: 'Rejected',
        disabled: false,
        selected: false
      },
      {
        value: 'DRAFT',
        label: 'Draft',
        disabled: false,
        selected: false
      }
    ]
  };

  inputCheckerStatusConfig = {
    id: 'checker-status-input',
    name: 'checker-status-input',
    label: 'Status',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All status',
        disabled: false,
        selected: true
      },
      {
        value: 'SUBMITTED_FOR_APPROVAL',
        label: 'Pending approval',
        disabled: false,
        selected: false
      },
      {
        value: 'REJECTED',
        label: 'Rejected',
        disabled: false,
        selected: false
      }
    ]
  };

  inputFileTypeConfig = {
    id: 'file-type-input',
    name: 'file-type-input',
    label: 'File type',
    value: '',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All file types',
        disabled: false,
        selected: true
      },
      {
        value: 'IMAGE',
        label: 'Image',
        disabled: false,
        selected: false
      },
      {
        value: 'DOCUMENT',
        label: 'Document',
        disabled: false,
        selected: false
      },
      {
        value: 'FOLDER',
        label: 'Folder',
        disabled: false,
        selected: false
      }
    ]
  };

  inputSortingConfig = {
    id: 'sorting-input',
    name: 'sorting-input',
    label: 'Sort by',
    value: '',
    disabled: false,
    error_message: '',
    options: [
      {
        value: '0',
        label: 'Date added (Latest to oldest)',
        disabled: false,
        selected: true
      },
      {
        value: '1',
        label: 'Date added (Oldest to latest)',
        disabled: false,
        selected: false
      },
      {
        value: '2',
        label: 'File name (A-Z)',
        disabled: false,
        selected: false
      },
      {
        value: '3',
        label: 'File name (Z-A)',
        disabled: false,
        selected: false
      }
    ]
  };

  inputCategoryConfig = {
    id: 'category-input',
    name: 'category-input',
    label: 'Category',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All categories',
        disabled: false,
        selected: true
      },
      {
        value: 'SbuxPublicHomeBanner',
        label: 'Public Home Banner',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxPublicHomeArticle',
        label: 'Public Home Article',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxHomeBanner',
        label: 'Home Banner',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxHomeRecommendedList',
        label: 'Home Recommended List',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxHomeExploreList',
        label: 'Home Explore List',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxHomeSpecialTopic',
        label: 'Home Special Topic',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxHomeNotice',
        label: 'Home Notice',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxHomeNotification',
        label: 'Home Notification',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEvent',
        label: 'Event',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEventActivity',
        label: 'Event Activity',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEventVideo',
        label: 'Event Video',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEventBanner',
        label: 'Event Banner',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxFeaturedEvent',
        label: 'Featured Event',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEventHighlight',
        label: 'Event Highlight',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEventArchive',
        label: 'Event Archive',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxEventSchedule',
        label: 'Event Schedule',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxLearningTopic',
        label: 'Learning Topic',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxLearningLesson',
        label: 'Learning Lesson',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxLearningFilter',
        label: 'Learning Filter',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxCompetition',
        label: 'Competition',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxCompetitionInformation',
        label: 'Competition Information',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxCompetitionVideo',
        label: 'Competition Video',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxCompetitionSteeringCommittee',
        label: 'Competition Steering Committee',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxCompetitionYearFilter',
        label: 'Competition Year Filter',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxBapTopic',
        label: 'Bap Topic',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxBapTopicItem',
        label: 'BapT opic Item',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxWorkshop',
        label: 'Workshop',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxWorkshopBanner',
        label: 'Workshop Banner',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxWorkshopArchive',
        label: 'WorkshopArchive',
        disabled: false,
        selected: false
      },
      {
        value: 'SbuxPerson',
        label: 'Person',
        disabled: false,
        selected: false
      },
    ]
  };

  inputAdminStatusConfig = {
    id: 'admin-status-input',
    name: 'admin-status-input',
    label: 'Status',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All',
        disabled: false,
        selected: true
      },
      {
        value: 'active',
        label: 'Active',
        disabled: false,
        selected: false
      },
      {
        value: 'inactive',
        label: 'Inactive',
        disabled: false,
        selected: false
      },
      {
        value: 'deleted',
        label: 'Deleted',
        disabled: false,
        selected: false
      }
    ]
  };

  inputAuditRoleConfig = {
    id: 'audit-role-input',
    name: 'audit-role-input',
    label: 'Role',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All',
        disabled: false,
        selected: true
      },
      {
        value: 'Checker',
        label: 'Checker',
        disabled: false,
        selected: true
      },
      {
        value: 'Maker',
        label: 'Maker',
        disabled: false,
        selected: false
      },
      {
        value: 'Security admin',
        label: 'Security admin',
        disabled: false,
        selected: false
      },
      {
        value: 'Super admin',
        label: 'Super admin',
        disabled: false,
        selected: false
      }
    ]
  };

  inputAuditStatusConfig = {
    id: 'audit-status-input',
    name: 'audit-status-input',
    label: 'Status',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All',
        disabled: false,
        selected: true
      }
    ]
  };

  inputAuditEventConfig = {
    id: 'audit-event-input',
    name: 'audit-event-input',
    label: 'Event',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All',
        disabled: false,
        selected: true
      }
    ]
  };

  inputAuditDateRangeConfig = {
    id: 'audit-date-range-input',
    name: 'audit-date-range-input',
    label: 'Date Range',
    placeholder: 'Select date range',
    value: [],
    disabled: false,
    error_message: 'Please select a registration date range',
    error: [''],
    calendar_only: true,
    picker_mode_single: false
  };

  inputPartnerStatusConfig = {
    id: 'partner-status-input',
    name: 'partner-status-input',
    label: 'Status',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All',
        disabled: false,
        selected: true
      },
      {
        value: 'active',
        label: 'Active',
        disabled: false,
        selected: false
      },
      {
        value: 'inactive',
        label: 'Inactive',
        disabled: false,
        selected: false
      },
      {
        value: 'deleted',
        label: 'Deleted',
        disabled: false,
        selected: false
      }
    ]
  };

  inputPartnerRankConfig = {
    id: 'partner-rank-input',
    name: 'partner-rank-input',
    label: 'Rank',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All ranks',
        disabled: false,
        selected: true
      },
      {
        value: 'GREEN_APRON',
        label: 'Green Apron',
        disabled: false,
        selected: true
      },
      {
        value: 'SCA_CERTIFIED_COFFEE_MASTER',
        label: 'SCA Certified Coffee Master',
        disabled: false,
        selected: false
      },
      {
        value: 'STAR_1',
        label: 'Black Apron Star 1',
        disabled: false,
        selected: false
      },
      {
        value: 'STAR_2',
        label: 'Black Apron Star 2',
        disabled: false,
        selected: false
      },
      {
        value: 'STAR_3',
        label: 'Black Apron Star 3',
        disabled: false,
        selected: false
      }
    ]
  };

  inputPartnerMarketConfig = {
    id: 'partner-market-input',
    name: 'partner-market-input',
    label: 'Market',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All',
        disabled: false,
        selected: true
      },
      {
        value: 'Malaysia',
        label: 'Malaysia',
        disabled: false,
        selected: false
      },
      {
        value: 'Hong Kong',
        label: 'Hong Kong',
        disabled: false,
        selected: false
      }
    ]
  };

  inputBapUploadsStatusConfig = {
    id: 'bap-uploads-status-input',
    name: 'bap-uploads-status-input',
    label: 'Status',
    value: 'ALL',
    disabled: false,
    error_message: '',
    options: [
      {
        value: 'ALL',
        label: 'All status',
        disabled: false,
        selected: true
      },
      {
        value: 'ACCEPTED',
        label: 'Accepted',
        disabled: false,
        selected: false
      },
      {
        value: 'VERIFIED',
        label: 'Verified',
        disabled: false,
        selected: false
      },
      {
        value: 'REJECTED',
        label: 'Rejected',
        disabled: false,
        selected: false
      }
    ]
  };

  inputBapUploadsDatesConfig = {
    id: 'bap-uploads-dates-input',
    name: 'bap-uploads-dates-input',
    label: 'Upload dates',
    placeholder: 'Select date range',
    value: [],
    disabled: false,
    error_message: 'Please select a date range',
    error: [''],
    calendar_only: true,
    picker_mode_single: false
  };

  @Output() manageOrderButtonClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private publicService: PublicService,
    private auditService: AuditService
  ) { }


  ngOnInit(): void {
    // Get Market (Manage Partner)
    this.publicService.getMarket().subscribe({
      next: res => {
        const marketOption: any[] = [{
          value: 'ALL',
          label: 'All',
          disabled: false,
          selected: true
        }];

        if (res.data) {
          res.data.forEach((market: any) => {
            marketOption.push({
              value: market.code,
              label: market.label,
              disabled: false,
              selected: false
            })
          });
          this.inputPartnerMarketConfig.options = marketOption;
        }
      }
    });

    // (Audit Trail)
    if (this.config.mode === 'AUDIT_TRAILS') {
      // Get Event
      this.auditService.getEvent().subscribe({
        next: res => {
          const eventOptions: any[] = [];

          if (res.data) {
            res.data.forEach((market: any) => {
              eventOptions.push({
                value: market.value,
                label: market.label,
                disabled: false,
                selected: false
              })
            });
            this.inputAuditEventConfig.options = [...this.inputAuditEventConfig.options, ...eventOptions];
          }
        }
      });

      // Get Status
      this.auditService.getStatus().subscribe({
        next: res => {
          const statusOptions: any[] = [];

          if (res.data) {
            res.data.forEach((market: any) => {
              statusOptions.push({
                value: market.value,
                label: market.label,
                disabled: false,
                selected: false
              })
            });
            this.inputAuditStatusConfig.options = [...this.inputAuditStatusConfig.options, ...statusOptions];
          }
        }
      })
    }
  }

  returnFilterValue(e: any) {
    this.onFilter.emit(e);
  }

  onManageOrderButton() {
    this.manageOrderButtonClick.emit();
  }
}
